<template>
    <div>
      <b-card>
        <list-report-head :base-url="exportTrophyCIPServiceBaseUrl" uri="/configuration/report-heading/detail" :org-id="2">
          <template v-slot:projectNameSlot>
            {{ }}
          </template>
          {{ currentLocale === 'en' ? 'Export assessment statement of export income and other information of exporters as per application form received for selection of National Export Trophy ' + getFiscalYearName(fiscalYearId) : 'জাতীয় রপ্তানি ট্রফি ' + getFiscalYearName(fiscalYearId) + ' নির্বাচনের জন্য প্রাপ্ত আবেদনপত্র অনুযায়ী রপ্তানীকারকগণের রপ্তানি আয় ও অন্যান্য তথ্যাবলীর রপ্তানি মূল্যয়ন বিবরণী' }}
        </list-report-head>
        <b-overlay :show="loading">
            <div class="py-2 px-2 text-center">
              <strong class="text-underline">({{ $n(1) }}) {{ $t('externalUser.export_product_sector') }} : {{ productName(listItem.product_service_sector_id) }}</strong>
            </div>
            <b-row class="mt-3">
              <b-col md="6">
                <b-table-simple small hover bordered responsive>
                  <b-tbody>
                    <b-tr>
                      <b-td>=></b-td>
                      <b-td>{{ $t('exportTrophy.full_value') }}</b-td>
                      <b-td class="text-center">{{ $n(100) }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-td>{{ $t('exportTrophy.serial_1') }}</b-td>
                      <b-td>{{ $t('exportTrophy.total_export_earning') }}</b-td>
                      <b-td class="text-center">{{ $n(60) }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-td>{{ $t('exportTrophy.serial_2') }}</b-td>
                      <b-td>{{ $t('exportTrophy.total_increase_income') }}</b-td>
                      <b-td class="text-center">{{ $n(10) }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-td>{{ $t('exportTrophy.serial_3') }}</b-td>
                      <b-td>{{ $t('exportTrophy.number_on_product_new_market') }}</b-td>
                      <b-td class="text-center">{{ $n(10) }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-td>{{ $t('exportTrophy.serial_4') }}</b-td>
                      <b-td>{{ $t('exportTrophy.total_number_new_product_add') }}</b-td>
                      <b-td class="text-center">{{ $n(10) }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-td>{{ $t('exportTrophy.serial_5') }}</b-td>
                      <b-td>{{ $t('exportTrophy.total_number_on_compliance') }}</b-td>
                      <b-td class="text-center">{{ $n(10) }}</b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </b-col>
              <b-col md="6">
                <b-table-simple small hover bordered responsive>
                  <b-tbody>
                    <b-tr>
                      <b-td>=></b-td>
                      <b-td>{{ $t('exportTrophy.miminum_export_revenue') }}</b-td>
                      <b-td class="text-center">{{ $n(70.00) }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-td>=></b-td>
                      <b-td>{{ $t('exportTrophy.total_application_number') }}</b-td>
                      <b-td class="text-center">{{ $n(30) }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-td>=></b-td>
                      <b-td>{{ $t('exportTrophy.total_eligible_number') }}</b-td>
                      <b-td class="text-center">{{ $n(27) }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-td>=></b-td>
                      <b-td>{{ $t('exportTrophy.incomplete_eligible') }}</b-td>
                      <b-td class="text-center">{{ $n(0) + $n(3) }}</b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </b-col>
            </b-row>
          <b-row class="mt-3">
            <b-col sm="12">
              <b-table-simple class="mt-3"  hover small bordered responsive>
                <b-thead>
                  <b-tr>
                    <b-th class="text-center align-top" rowspan="2">{{ $t('globalTrans.sl_no') }}</b-th>
                    <b-th class="text-center align-top" rowspan="2">{{ $t('globalTrans.sl_no') }}</b-th>
                    <b-th class="text-center align-top" rowspan="2">{{ $t('exportTrophy.org_name_address') }}</b-th>
                    <b-th class="text-center align-top">{{ $t('exportTrophy.organization_owner_info') }}</b-th>
                    <b-th class="text-center align-top" colspan="3">{{ $t('exportTrophyConfig.minimum_income_usd') }} {{ $t('exportTrophy.serial_1') }}</b-th>
                    <b-th class="text-center align-top">
                      <span v-if="currentLocale === 'en'">{{ 'Income growth in ' }}</span>
                      {{ getFiscalYearName(fiscalYearPrevTwoId) }}
                      <span>{{ currentLocale === 'bn' ? ' এর তুলনায়' : ' fiscal year compared to'}}</span>
                      {{ getFiscalYearName(fiscalYearPrevId) }}
                      <span v-if="currentLocale === 'bn'">{{ ' অর্থ বছরের রপ্তানি প্রবৃদ্ধি' }}</span>
                      {{ $t('exportTrophy.serial_2') }}
                    </b-th>
                    <!-- <b-th class="text-center align-top">{{ $t('exportTrophy.export_markets') }}</b-th> -->
                    <b-th class="text-center align-top">{{ $t('exportTrophy.entering_new_market') }} {{ $t('exportTrophy.serial_3') }}</b-th>
                    <b-th class="text-center align-top">{{ $t('exportTrophy.new_product_addition') }} {{ $t('exportTrophy.serial_4') }}</b-th>
                    <b-th class="text-center align-top">{{  $t('exportTrophy.compliance') }} {{ $t('exportTrophy.serial_5') }}</b-th>
                    <b-th class="text-center align-top">{{  $t('globalTrans.comments') }}</b-th>
                  </b-tr>
                  <b-tr>
                    <b-th></b-th>
                    <b-th class="text-center">{{ getFiscalYearName(fiscalYearPrevTwoId) }}</b-th>
                    <b-th colspan="2" class="text-center">{{ getFiscalYearName(fiscalYearPrevId) }}</b-th>
                    <b-th></b-th>
                    <!-- <b-th></b-th> -->
                    <b-th></b-th>
                    <b-th></b-th>
                    <b-th></b-th>
                    <b-th></b-th>
                  </b-tr>
                  <b-tr>
                    <b-th class="text-center">{{ $n(1) }}</b-th>
                    <b-th class="text-center">{{ $n(2) }}</b-th>
                    <b-th class="text-center">{{ $n(3) }}</b-th>
                    <b-th class="text-center">{{ $n(4) }}</b-th>
                    <b-th class="text-center">{{ $n(5) }}</b-th>
                    <b-th class="text-center">{{ $t('exportTrophy.b_prc') }}</b-th>
                    <b-th class="text-center">{{ $t('exportTrophy.bb_data') }}</b-th>
                    <b-th class="text-center">{{ $n(6) }}</b-th>
                    <!-- <b-th class="text-center">{{ $n(7) }}</b-th> -->
                    <b-th class="text-center">{{ $n(7) }}</b-th>
                    <b-th class="text-center">{{ $n(8) }}</b-th>
                    <b-th class="text-center">{{ $n(1) }}</b-th>
                    <b-th class="text-center">{{ $n(2) }}</b-th>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <template v-if="listData.length">
                    <template v-for="(item, index) in listData">
                      <b-tr :key="'row-1-'+ index">
                        <b-td class="text-center">{{ $n(index + 1) }}</b-td>
                        <b-td class="text-center">
                          <template v-if="index < 9">{{ $n(0) }}</template>{{ $n(index + 1) }}
                        </b-td>
                        <b-td>
                          <span>
                            <strong>{{ item.org_info ? (currentLocale === 'bn' ? item.org_info.org_name_bn : item.org_info.org_name_en) : '' }} </strong><br/>
                            {{ item.org_address ? AddressHelper.getCommonAddress(item.org_address) : '' }} <br>
                            {{ $t('globalTrans.phone_no') }}:
                            <template v-if="item.contact_info && item.contact_info.telephone_no">
                              {{ item.contact_info.telephone_no | numberConvert }}
                            </template>
                            <template v-else>N/A</template>
                            <br>
                            {{ $t('globalTrans.mobile') }}:
                            <template v-if="item.contact_info && item.contact_info.mobile_no">
                              {{ item.contact_info.mobile_no | numberConvert }}
                            </template>
                            <template v-else>N/A</template><br>
                            {{ $t('globalTrans.email') }}:
                            <template v-if="item.contact_info && item.contact_info.email">
                              {{ item.contact_info.email }}
                            </template>
                            <template v-else>N/A</template>
                          </span>
                        </b-td>
                        <b-td>
                          <strong>{{ currentLocale === 'en' ? item.owner_info.owner_name_en : item.owner_info.owner_name_bn }}</strong> <br>
                          {{ currentLocale === 'en' ? item.owner_info.designation_en : item.owner_info.designation_bn }} <br>
                          {{ $t('globalTrans.father_name') }}: {{ item.owner_info.father_title_en && item.owner_info.father_title_bn ? (currentLocale === 'en' ? item.owner_info.father_title_en : item.owner_info.father_title_bn) : 'N/A' }} <br>
                          {{ $t('globalTrans.mother_name') }}: {{ item.owner_info.mother_title_en && item.owner_info.mother_title_bn ? (currentLocale === 'en' ? item.owner_info.mother_title_en : item.owner_info.mother_title_bn) : 'N/A' }} <br>
                          {{ item.address ? AddressHelper.getCommonAddress(getPresentAddressData(item.address)) : '' }} <br>
                          <span class="text-underline">{{ $t('globalTrans.permanent_address') }}</span>
                          <template v-if="item.address">
                            {{ AddressHelper.getCommonAddress(getPermanentAddressData(item.address)) }}
                          </template>
                          <template v-else>N/A</template>
                        </b-td>
                        <b-td class="text-center">{{ $n(item.evaluation.previous_export_income_usd, { useGrouping: false }) }}</b-td>
                        <b-td class="text-center">{{ $n(item.evaluation.current_export_income_usd, { useGrouping: false }) }}</b-td>
                        <b-td class="text-center">{{ $n(item.evaluation.bb_export_income_usd, { useGrouping: false }) }}</b-td>
                        <b-td class="text-center">{{ $n(item.evaluation.income_growth, { useGrouping: false }) + '%' }}</b-td>
                        <!-- <b-td class="text-center"></b-td> -->
                        <b-td class="text-center">{{ getCountryNames(item.export_destination) }}</b-td>
                        <b-td class="text-center">{{ getProductNames(item.export_product) }}</b-td>
                        <b-td class="text-center">
                          {{ item.other_compliance && item.other_compliance.compliance_current_fiscal_year === 1 ? $t('exportTrophy.compliance_info') : '-' }}
                        </b-td>
                        <b-td class="text-center">
                          {{ item.evaluation.position > 0 ? $t('exportTrophy.position') + ': ' + $n(item.evaluation.position, { useGrouping: false }) : '-' }}
                        </b-td>
                      </b-tr>
                      <b-tr :key="'row-2-'+ index">
                        <b-td></b-td>
                        <b-td></b-td>
                        <b-td></b-td>
                        <b-td></b-td>
                        <b-td></b-td>
                        <b-td></b-td>
                        <b-td>
                          {{ $n(item.evaluation.bb_export_income_number) }}
                        </b-td>
                        <b-td class="text-center">
                          {{ $n(item.evaluation.income_growth_number) }}
                        </b-td>
                        <!-- <b-td></b-td> -->
                        <b-td class="text-center">
                          {{ $n(item.evaluation.new_destination_number) }}
                        </b-td>
                        <b-td class="text-center">
                          {{ $n(item.evaluation.new_product_number) }}
                        </b-td>
                        <b-td class="text-center">
                          {{ $n(item.evaluation.compliance_number) }}
                        </b-td>
                        <b-td class="text-center">
                          {{ $n(item.evaluation.total_number) }}
                        </b-td>
                      </b-tr>
                    </template>
                  </template>
                  <b-tr v-else>
                    <b-td colspan="12" align="center">{{ $t('globalTrans.noDataFound') }}</b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
              <!-- <div class="pagination-wrapper mt-3">
                <b-pagination
                  v-model="pagination.currentPage"
                  :per-page="pagination.perPage"
                  :total-rows="pagination.totalRows"
                  @input="loadData"
                />
              </div> -->
            </b-col>
        </b-row>
        </b-overlay>
      </b-card>
    </div>
</template>
<script>
import RestApi, { exportTrophyCIPServiceBaseUrl } from '@/config/api_config'
import { scrutinyEvaluationApplicantsApi } from '../../api/routes'
import ListReportHead from '@/components/custom/ListReportHead.vue'
import AddressHelper from '@/utils/area-type-address'
import ExportPdf from './evaluation_sheet_pdf'

export default {
  name: 'EvaluationSheet',
  props: ['listItem'],
  components: {
    ListReportHead
  },
  data () {
    return {
      exportTrophyCIPServiceBaseUrl: exportTrophyCIPServiceBaseUrl,
      listData: [],
      fiscalYearId: 0,
      fiscalYearPrevId: 0,
      fiscalYearPrevTwoId: 0,
      search: {
        limit: 20
      },
      pagination: {
        currentPage: 1,
        totalRows: 0,
        perPage: 20,
        slOffset: 1
      },
      AddressHelper: AddressHelper,
      headerExcelDefault: {
        orgName: null,
        orgNameBn: null,
        address: null,
        address_bn: null
      }
    }
  },
  created () {
    this.headerDataExcel()
    if (this.listItem.product_service_sector_id && this.listItem.circular_id) {
      const obj = this.$store.state.ExportTrophyCipService.commonObj.circularList.find(item => item.value === parseInt(this.listItem.circular_id))
      this.fiscalYearId = obj.fiscal_year_id
      const fiscalYear = this.fiscalYearList.find(item => item.value === parseInt(this.fiscalYearId))
      const fiscalYearPrev = this.fiscalYearList.find(item => item.sorting_order === parseInt(fiscalYear.sorting_order - 1))
      const fiscalYearPrevTwo = this.fiscalYearList.find(item => item.sorting_order === parseInt(fiscalYear.sorting_order - 2))
      this.fiscalYearPrevId = fiscalYearPrev.value
      this.fiscalYearPrevTwoId = fiscalYearPrevTwo.value
    }
  },
  computed: {
    loading () {
      return this.$store.state.commonObj.loading
    },
    currentLocale () {
      return this.$i18n.locale
    },
    fiscalYearList () {
      return this.$store.state.CommonService.commonObj.fiscalYearList
    },
    headerValue: function () {
      const headerVal = []
      // const space = '\xa0\xa0\xa0\xa0'
      if (this.$i18n.locale === 'en') {
        headerVal[0] = this.$t('globalTrans.government_republic_of_bangladesh')
        headerVal[1] = this.headerExcelDefault.orgName
        headerVal[2] = this.headerExcelDefault.address
        headerVal[3] = 'Export assessment statement of export income and other information of exporters as per application form received for selection of National Export Trophy ' + this.getFiscalYearName(this.fiscalYearId)
        headerVal[4] = ''
        headerVal[5] = ''
        headerVal[6] = ''
        // headerVal[5] = '(' + this.$n(1) + ') ' + this.$t('externalUser.export_product_sector', 'en') + ' : ' + this.productName(this.listItem.product_service_sector_id)
        // headerVal[6] = '#\xa0\xa0' + space + this.showTextWithSpace(this.$t('exportTrophy.full_value', 'en')) + space + this.$n(100) + space + space + '#' + space + this.showTextWithSpace(this.$t('exportTrophy.miminum_export_revenue', 'en'), 130) + space + this.$n(70.00)
        // headerVal[7] = this.$t('exportTrophy.serial_1', 'en') + space + this.showTextWithSpace(this.$t('exportTrophy.total_export_earning', 'en')) + space + this.showTextWithSpace(this.$n(60), 3, false) + space + space + '#' + space + this.showTextWithSpace(this.$t('exportTrophy.total_application_number', 'en'), 130) + space + this.$n(30)
      } else {
        headerVal[0] = this.$t('globalTrans.government_republic_of_bangladesh')
        headerVal[1] = this.headerExcelDefault.orgNameBn
        headerVal[2] = this.headerExcelDefault.address_bn
        headerVal[3] = 'জাতীয় রপ্তানি ট্রফি ' + this.getFiscalYearName(this.fiscalYearId) + ' নির্বাচনের জন্য প্রাপ্ত আবেদনপত্র অনুযায়ী রপ্তানীকারকগণের রপ্তানি আয় ও অন্যান্য তথ্যাবলীর রপ্তানি মূল্যয়ন বিবরণী'
        headerVal[4] = ''
        headerVal[5] = ''
        headerVal[6] = ''
        // headerVal[5] = '(' + this.$n(1) + ') ' + this.$t('externalUser.export_product_sector', 'bn') + ' : ' + this.productName(this.listItem.product_service_sector_id)
        // headerVal[6] = '# ' + space + this.showTextWithSpace(this.$t('exportTrophy.full_value', 'bn')) + space + this.$n(100) + space + space + '#' + space + this.showTextWithSpace(this.$t('exportTrophy.miminum_export_revenue', 'bn'), 130) + space + this.$n(70.00)
        // headerVal[7] = ''
      }
      return headerVal
    },
    excelData: function () {
      const list = []
      const headerObj = {}
      const headerObj2 = {}
      let serial1, serial2, orgNameAddress, orgOwnerInfo, minimumIncomeUsd, incomeGrowthIn,
          enteringNewMarket, newProductAddition, compliance, comments
      if (this.currentLocale === 'en') {
        serial1 = 'SL'
        serial2 = 'SL '
        orgNameAddress = this.$t('exportTrophy.org_name_address', 'en')
        orgOwnerInfo = this.$t('exportTrophy.organization_owner_info', 'en')
        minimumIncomeUsd = this.$t('exportTrophyConfig.minimum_income_usd', 'en') + ' ' + this.$t('exportTrophy.serial_1', 'en')
        incomeGrowthIn = 'Income growth in ' + this.getFiscalYearName(this.fiscalYearPrevTwoId) + ' fiscal year compared to' + this.getFiscalYearName(this.fiscalYearPrevId) + this.$t('exportTrophy.serial_2', 'en')
        enteringNewMarket = this.$t('exportTrophy.entering_new_market', 'en') + ' ' + this.$t('exportTrophy.serial_3', 'en')
        newProductAddition = this.$t('exportTrophy.new_product_addition', 'en') + '  ' + this.$t('exportTrophy.serial_4', 'en')
        compliance = this.$t('exportTrophy.compliance', 'en') + ' ' + this.$t('exportTrophy.serial_5', 'en')
        comments = this.$t('globalTrans.comments', 'en')
      } else {
        serial1 = 'ক্রমিক সংখ্যা'
        serial2 = 'ক্রমিক সংখ্যা '
        orgNameAddress = this.$t('exportTrophy.org_name_address', 'bn')
        orgOwnerInfo = this.$t('exportTrophy.organization_owner_info', 'bn')
        minimumIncomeUsd = this.$t('exportTrophyConfig.minimum_income_usd', 'bn') + ' ' + this.$t('exportTrophy.serial_1', 'bn')
        incomeGrowthIn = this.getFiscalYearName(this.fiscalYearPrevTwoId) + ' এর তুলনায় ' + this.getFiscalYearName(this.fiscalYearPrevId) + ' অর্থ বছরের রপ্তানি প্রবৃদ্ধি ' + this.$t('exportTrophy.serial_2', 'bn')
        enteringNewMarket = this.$t('exportTrophy.entering_new_market', 'bn') + ' ' + this.$t('exportTrophy.serial_3', 'bn')
        newProductAddition = this.$t('exportTrophy.new_product_addition', 'bn') + '  ' + this.$t('exportTrophy.serial_4', 'bn')
        compliance = this.$t('exportTrophy.compliance', 'bn') + ' ' + this.$t('exportTrophy.serial_5', 'bn')
        comments = this.$t('globalTrans.comments', 'bn')
      }
      // header 1
      headerObj[serial1] = ' '
      headerObj[serial2] = ' '
      headerObj[orgNameAddress] = ' '
      headerObj[orgOwnerInfo] = ' '
      headerObj[minimumIncomeUsd] = this.getFiscalYearName(this.fiscalYearPrevTwoId)
      headerObj[' '] = this.getFiscalYearName(this.fiscalYearPrevId)
      headerObj['  '] = ' '
      headerObj[incomeGrowthIn] = ' '
      headerObj[enteringNewMarket] = ' '
      headerObj[newProductAddition] = ' '
      headerObj[compliance] = ' '
      headerObj[comments] = ' '
      // header 2
      headerObj2[serial1] = this.$n(1)
      headerObj2[serial2] = this.$n(2)
      headerObj2[orgNameAddress] = this.$n(3)
      headerObj2[orgOwnerInfo] = this.$n(4)
      headerObj2[minimumIncomeUsd] = this.$n(5)
      headerObj2[' '] = this.$t('exportTrophy.b_prc')
      headerObj2['  '] = this.$t('exportTrophy.bb_data')
      headerObj2[incomeGrowthIn] = this.$n(6)
      headerObj2[enteringNewMarket] = this.$n(7)
      headerObj2[newProductAddition] = this.$n(8)
      headerObj2[compliance] = this.$n(1)
      headerObj2[comments] = this.$n(2)
      list.push(headerObj, headerObj2)
      if (this.listData.length) {
        this.listData.forEach((item, index) => {
          const listItem = {}
          listItem[serial1] = this.$n(index + 1)
          listItem[serial2] = (index < 9 ? this.$n(0) : '') + this.$n(index + 1)
          listItem[orgNameAddress] = this.getOrgNameAddressInfo(item)
          listItem[orgOwnerInfo] = this.getOrgOwnerInfo(item)
          listItem[minimumIncomeUsd] = this.$n(item.evaluation.previous_export_income_usd, { useGrouping: false })
          listItem[' '] = this.$n(item.evaluation.current_export_income_usd, { useGrouping: false })
          listItem['  '] = this.$n(item.evaluation.bb_export_income_usd, { useGrouping: false })
          listItem[incomeGrowthIn] = this.$n(item.evaluation.income_growth, { useGrouping: false }) + '%'
          listItem[enteringNewMarket] = this.getCountryNames(item.export_destination)
          listItem[newProductAddition] = this.getProductNames(item.export_product)
          listItem[compliance] = item.other_compliance && item.other_compliance.compliance_current_fiscal_year === 1 ? this.$t('exportTrophy.compliance_info') : '-'
          listItem[comments] = item.evaluation.position > 0 ? this.$t('exportTrophy.position') + ': ' + this.$n(item.evaluation.position, { useGrouping: false }) : '-'
          const listItem2 = {}
          listItem2[serial1] = ' '
          listItem2[serial2] = ' '
          listItem2[orgNameAddress] = ' '
          listItem2[orgOwnerInfo] = ' '
          listItem2[minimumIncomeUsd] = ' '
          listItem2[' '] = ' '
          listItem2['  '] = this.$n(item.evaluation.bb_export_income_number)
          listItem2[incomeGrowthIn] = this.$n(item.evaluation.income_growth_number)
          listItem2[enteringNewMarket] = this.$n(item.evaluation.new_destination_number)
          listItem2[newProductAddition] = this.$n(item.evaluation.new_product_number)
          listItem2[compliance] = this.$n(item.evaluation.compliance_number)
          listItem2[comments] = this.$n(item.evaluation.total_number)
          list.push(listItem, listItem2)
        })
      } else {
        const blankRowObj = {}
        blankRowObj[serial1] = ' '
        blankRowObj[serial2] = ' '
        blankRowObj[orgNameAddress] = ' '
        blankRowObj[orgOwnerInfo] = ' '
        blankRowObj[minimumIncomeUsd] = this.$t('globalTrans.noDataFound')
        blankRowObj[' '] = ' '
        blankRowObj['  '] = ' '
        blankRowObj[incomeGrowthIn] = ' '
        blankRowObj[enteringNewMarket] = ' '
        blankRowObj[newProductAddition] = ' '
        blankRowObj[compliance] = ' '
        blankRowObj[comments] = ' '
        list.push(blankRowObj)
      }
      return list
    }
  },
  methods: {
    headerDataExcel () {
      RestApi.getData(exportTrophyCIPServiceBaseUrl, '/configuration/report-heading/detail/2').then(response => {
        if (response.success) {
          const orgList = this.$store.state.CommonService.commonObj.componentOrgList.find(item => item.value === 2)
          const orgName = typeof orgList !== 'undefined' ? orgList.text_en : ''
          const orgNameBn = typeof orgList !== 'undefined' ? orgList.text_bn : ''
          this.headerExcelDefault.orgName = orgName
          this.headerExcelDefault.orgNameBn = orgNameBn
          this.headerExcelDefault.address = response.data.address
          this.headerExcelDefault.address_bn = response.data.address_bn
          this.loadData()
        }
      })
    },
    getSectorName (id) {
      const obj = this.$store.state.ExportTrophyCipService.commonObj.exportSectorList.find(item => item.value === parseInt(id))
      return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
    },
    getFiscalYearName (id) {
      const obj = this.$store.state.CommonService.commonObj.fiscalYearList.find(item => item.value === parseInt(id))
      return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
    },
    async loadData () {
      const params = Object.assign({ show_all: 1 }, this.search, this.listItem)
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const result = await RestApi.getData(exportTrophyCIPServiceBaseUrl, scrutinyEvaluationApplicantsApi, params)
      if (result.success) {
        this.listData = result.data
        this.totalApplication = result.total_application
        this.minimumIncomeApplicant = this.getMinimumIncomeApplicant(result.data)
        this.setExcelData()
      } else {
        this.listData = []
        this.totalApplication = 0
        this.setExcelData()
      }
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    getMinimumIncomeApplicant (data) {
      let total = 0
      data.forEach((item, index) => {
        let totalIncome = 0
        const thisFiscal = item.product_success_description[1].fiscal_year_id
        // gel total income for current fiscal year [index 0 always previous fiscal and index 1 current fiscal]
        const FilterData = item.product_success_description.filter(el => el.fiscal_year_id === thisFiscal)
        FilterData.forEach(newitem => {
          totalIncome = totalIncome + newitem.export_value_ssd
        })
        if (totalIncome < this.minimumExportIncome) {
          total = total + 1
        }
      })
      return total
    },
    getMinimumIncome (Id) {
      const Obj = this.$store.state.ExportTrophyCipService.commonObj.assignIncomeList.find(item => item.sector_id === parseInt(Id))
      return Obj ? Obj.minimum_income : 0
    },
    productName (Id) {
      const Obj = this.$store.state.ExportTrophyCipService.commonObj.exportSectorList.find(item => item.value === parseInt(Id))
      return this.currentLocale === 'bn' ? Obj?.text_bn : Obj?.text_en
    },
    getCountryNames (data) {
      let names = '-'
      if (data.length) {
        const arr = []
        data.forEach(item => {
          const obj = this.$store.state.CommonService.commonObj.countryList.find(el => el.value === parseInt(item.country_id))
          if (obj !== undefined) {
            arr.push(obj.text)
          }
        })
        names = arr.toString()
      }
      return names
    },
    getProductNames (data) {
      let names = '-'
      if (data.length) {
        const arr = []
        data.forEach(item => {
          const obj = this.$store.state.ExportTrophyCipService.commonObj.exportSectorList.find(el => el.value === parseInt(item.product_id))
          if (obj !== undefined) {
            arr.push(obj.text)
          }
        })
        names = arr.toString()
      }
      return names
    },
    getPresentAddressData (data) {
      const outputData = {
        address_details_en: data.pr_address_details_en,
        address_details_bn: data.pr_address_details_bn,
        ward_id: data.pr_ward_id,
        union_id: data.pr_union_id,
        city_corporation_id: data.pr_city_corporation_id,
        pauroshoba_id: data.pr_paurashava_id,
        upazila_id: data.pr_upazila_id,
        district_id: data.pr_district_id,
        division_id: data.pr_division_id
      }
      return outputData
    },
    getPermanentAddressData (data) {
      const outputData = {
        address_details_en: data.pm_address_details_en,
        address_details_bn: data.pm_address_details_bn,
        ward_id: data.pm_ward_id,
        union_id: data.pm_union_id,
        city_corporation_id: data.pm_city_corporation_id,
        pauroshoba_id: data.pm_paurashava_id,
        upazila_id: data.pm_upazila_id,
        district_id: data.pm_district_id,
        division_id: data.pm_division_id
      }
      return outputData
    },
    pdfExport () {
      const reportTitle = this.currentLocale === 'en' ? 'Export assessment statement of export income and other information of exporters as per application form received for selection of National Export Trophy ' + this.getFiscalYearName(this.fiscalYearId) : 'জাতীয় রপ্তানি ট্রফি ' + this.getFiscalYearName(this.fiscalYearId) + ' নির্বাচনের জন্য প্রাপ্ত আবেদনপত্র অনুযায়ী রপ্তানীকারকগণের রপ্তানি আয় ও অন্যান্য তথ্যাবলীর রপ্তানি মূল্যয়ন বিবরণী'
      return ExportPdf.exportPdfDetails(exportTrophyCIPServiceBaseUrl, '/configuration/report-heading/detail', 2, reportTitle, this)
    },
    setExcelData () {
      this.$emit('get-excel-data', {
        headerValue: this.headerValue,
        headerExcelDefault: this.headerExcelDefault,
        excelData: this.excelData
      })
    },
    getOrgNameAddressInfo (item) {
      const text = (item.org_info ? (this.currentLocale === 'bn' ? item.org_info.org_name_bn : item.org_info.org_name_en) : '') + '\n' +
      (item.org_address ? this.AddressHelper.getCommonAddress(item.org_address) : '') + '\n' +
      this.$t('globalTrans.phone_no') + ' : ' +
      (item.contact_info && item.contact_info.telephone_no ? this.$options.filters.numberConvert(item.contact_info.telephone_no) : 'N/A') + '\n' +
      this.$t('globalTrans.mobile') + ' : ' +
      (item.contact_info && item.contact_info.mobile_no ? this.$options.filters.numberConvert(item.contact_info.mobile_no) : 'N/A') + '\n' +
      this.$t('globalTrans.email') + ' : ' +
      (item.contact_info && item.contact_info.email ? item.contact_info.email : 'N/A')
      return text
    },
    getOrgOwnerInfo (item) {
      const text = (this.currentLocale === 'en' ? item.owner_info.owner_name_en : item.owner_info.owner_name_bn) + '\n' +
      (this.currentLocale === 'en' ? item.owner_info.designation_en : item.owner_info.designation_bn) + '\n' +
      this.$t('globalTrans.father_name') + ' : ' +
      (item.owner_info.father_title_en && item.owner_info.father_title_bn ? (this.currentLocale === 'en' ? item.owner_info.father_title_en : item.owner_info.father_title_bn) : 'N/A') + '\n' +
      this.$t('globalTrans.mother_name') + ' : ' + (item.owner_info.mother_title_en && item.owner_info.mother_title_bn ? (this.currentLocale === 'en' ? item.owner_info.mother_title_en : item.owner_info.mother_title_bn) : 'N/A') + '\n' +
      (item.address ? this.AddressHelper.getCommonAddress(this.getPresentAddressData(item.address)) : '') + '\n' +
      this.$t('globalTrans.permanent_address') + ' : ' +
      (item.address ? this.AddressHelper.getCommonAddress(this.getPermanentAddressData(item.address)) : 'N/A')
      return text
    },
    showTextWithSpace (str, totatLen = 70, isPadEnd = true, padChar = '\xa0') {
      const newStr = str.toString()
      // const strLen = newStr.length
      // const restLen = totatLen - strLen
      // if (restLen > 0) {
        if (isPadEnd) {
          return newStr.padEnd(totatLen, padChar)
        } else {
          return newStr.padStart(totatLen, padChar)
        }
      // }
      // return newStr
    }
  }
}
</script>
<style scoped>
.list-disc {
  list-style-type: disc;
  padding-left: 1rem;
}
</style>
