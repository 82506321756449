<template>
    <b-container fluid>
        <b-overlay :show="loading">
          <b-row>
            <b-col>
              <list-report-head :base-url="exportTrophyCIPServiceBaseUrl" uri="/configuration/report-heading/detail" :org-id="2">
                <template v-slot:projectNameSlot>
                  {{ }}
                </template>
                {{ $t('exportTrophy.evaluated_application_list') }}
              </list-report-head>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12" class="table-responsive table-wrapper">
              <b-table thead-class="table_head" striped bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                <template v-slot:cell(index)="data">
                  {{ $n(data.index + 1) }}
                </template>
                <template v-slot:cell(application_id)="data">
                  {{ $n(data.item.application_id, { useGrouping: false }) }}
                </template>
                <template v-slot:cell(orgAddress)="data">
                  <span>
                    {{ data.item.org_info ? (currentLocale === 'bn' ? data.item.org_info.org_name_bn : data.item.org_info.org_name_en) : '' }} <br/>
                    {{ data.item.org_address ? AddressHelper.getCommonAddress(data.item.org_address) : '' }}
                  </span>
                </template>
                <template v-slot:cell(tin_no)="data">
                  <span v-if="data.item.org_tax_info">
                  {{ data.item.org_tax_info ? $n(data.item.org_tax_info.tin_no, { useGrouping: false }) : '' }}
                  </span>
                </template>
                <template v-slot:cell(total_number)="data">
                  <span v-if="data.item.evaluation">
                  {{ $n(data.item.evaluation.total_number, { useGrouping: false }) }}
                  </span>
                </template>
                <template v-slot:cell(position)="data">
                  <span v-if="data.item.evaluation">
                  {{ $n(data.item.evaluation.position, { useGrouping: false }) }}
                  </span>
                </template>
                 <template v-slot:cell(org_tax_info)="data">
                  {{ data.item.org_tax_info ? $n(data.item.org_tax_info.tin_no, { useGrouping: false }) : '' }}
                </template>
                <template v-slot:cell(org_info)="data">
                  {{ data.item.org_info ? $n(data.item.org_info.bin_no, { useGrouping: false }) : '' }}
                </template>
                <template v-slot:cell(action)="data">
                  <b-button v-b-modal.modal-details variant=" action-btn status mr-1" size="sm" @click="singleData(data.item)" class="action-btn status" :title="$t('globalTrans.details')"><i class="ri-eye-fill"></i></b-button>
                </template>
              </b-table>
            </b-col>
          </b-row>
        </b-overlay>
        <b-modal id="modal-details" size="xl" :title="detailsTitle" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <Details :id="editItemId" :key="editItemId"/>
        </b-modal>
    </b-container>
</template>
<script>
import RestApi, { exportTrophyCIPServiceBaseUrl } from '@/config/api_config'
import { scrutinyEvaluationApplicantsApi } from '../api/routes'
import Details from './Details.vue'
import ListReportHead from '@/components/custom/ListReportHead.vue'
import AddressHelper from '@/utils/area-type-address'
import ExportPdf from './evaluated-application-pdf.js'
export default {
    props: ['circular_id'],
    components: {
        Details,
        ListReportHead
    },
    data () {
        return {
            exportTrophyCIPServiceBaseUrl: exportTrophyCIPServiceBaseUrl,
            editItemId: 0,
            AddressHelper: AddressHelper,
            listData: []
        }
    },
    created () {
        this.loadData()
    },
    computed: {
        loading () {
          return this.$store.state.commonObj.loading
        },
        columns () {
            const labels = [
                { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
                { label: this.$t('globalTrans.application_id'), class: 'text-center' },
                { label: this.$t('exportTrophy.org_name_address'), class: 'text-left' },
                { label: this.$t('exportTrophy.owner_or_md_name'), class: 'text-left' },
                { label: this.$t('externalUser.tin_no'), class: 'text-center' },
                { label: this.$t('globalTrans.vat') + '/ ' + this.$t('externalUser.bin_no'), class: 'text-center' },
                { label: this.$t('exportTrophy.number_obtained'), class: 'text-center' },
                { label: this.$t('exportTrophy.position'), class: 'text-center' },
                { label: this.$t('globalTrans.action'), class: 'text-center' }
                ]

            let keys = []

            if (this.$i18n.locale === 'bn') {
                keys = [
                { key: 'index' },
                { key: 'application_id' },
                { key: 'orgAddress' },
                { key: 'owner_info.owner_name_bn' },
                { key: 'org_tax_info' },
                { key: 'org_info' },
                { key: 'total_number' },
                { key: 'position' },
                { key: 'action' }
                ]
            } else {
                keys = [
                { key: 'index' },
                { key: 'application_id' },
                { key: 'orgAddress' },
                { key: 'owner_info.owner_name_en' },
                { key: 'org_tax_info' },
                { key: 'org_info' },
                { key: 'total_number' },
                { key: 'position' },
                { key: 'action' }
                ]
            }

            return labels.map((item, index) => {
                return Object.assign(item, keys[index])
            })
        },
        detailsTitle () {
            return this.$t('externalUser.export_data_application_form') + ' ' + this.$t('globalTrans.details')
        },
        currentLocale () {
            return this.$i18n.locale
        }
    },
    methods: {
        async loadData () {
            const params = Object.assign({}, { status: 6 })
            this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
            const result = await RestApi.getData(exportTrophyCIPServiceBaseUrl, scrutinyEvaluationApplicantsApi, params)
            if (result.success) {
                this.listData = result.data
            } else {
              this.listData = []
            }
            this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
        },
        singleData (item) {
          this.editItemId = item.id
        },
        pdfExport () {
          const reportTitle = this.$t('exportTrophy.evaluated_application_list')
          ExportPdf.exportPdfDetails(exportTrophyCIPServiceBaseUrl, '/configuration/report-heading/detail', 2, reportTitle, this)
        }
    }
}
</script>
