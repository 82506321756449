import i18n from '@/i18n'
import Store from '@/store'
import ReportHeading from '@/utils/report-head'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'
import AddressHelper from '@/utils/area-type-address'
import { numberConvert } from '@/utils/fliter'

function getOrganizationInfoText (vm, item) {
  let textContent = ''
  if (item.org_info) {
    if (i18n.locale === 'bn') {
      textContent += item.org_info.org_name_bn + '\n'
    } else {
      textContent += item.org_info.org_name_en + '\n'
    }
  }
  let textContent2 = ''
  if (item.org_address) {
    textContent2 += AddressHelper.getCommonAddress(item.org_address) + '\n'
  }
  textContent2 += vm.$t('globalTrans.phone_no') + ': '
  if (item.contact_info && item.contact_info.telephone_no) {
    textContent2 += numberConvert(item.contact_info.telephone_no) + '\n'
  }
  textContent2 += vm.$t('globalTrans.mobile') + ': '
  if (item.contact_info && item.contact_info.mobile_no) {
    textContent2 += numberConvert(item.contact_info.mobile_no) + '\n'
  }
  textContent2 += vm.$t('globalTrans.email') + ': '
  if (item.contact_info && item.contact_info.email) {
    textContent2 += numberConvert(item.contact_info.email)
  }
  const customText = [
    { text: textContent, style: 'td', alignment: 'left', bold: true },
    { text: textContent2, style: 'td', alignment: 'left' }
  ]
  return customText
}

function getOwnerInfoText (vm, item) {
  const currentLocale = vm.currentLocale
  let textContent = ''
  if (item.owner_info) {
    if (i18n.locale === 'bn') {
      textContent += item.owner_info.owner_name_bn + '\n'
    } else {
      textContent += item.owner_info.owner_name_en + '\n'
    }
  }
  let textContent2 = ''
  if (item.owner_info) {
    textContent2 += (currentLocale === 'en' ? item.owner_info.designation_en : item.owner_info.designation_bn) + '\n'
  }
  textContent2 += vm.$t('globalTrans.father_name') + ': '
  if (item.owner_info && item.owner_info.father_title_en && item.owner_info.father_title_bn) {
    textContent2 += (currentLocale === 'en' ? item.owner_info.father_title_en : item.owner_info.father_title_bn) + '\n'
  } else {
    textContent2 += 'N/A' + '\n'
  }
  textContent2 += vm.$t('globalTrans.mother_name') + ': '
  if (item.owner_info && item.owner_info.mother_title_en && item.owner_info.mother_title_bn) {
    textContent2 += (currentLocale === 'en' ? item.owner_info.mother_title_en : item.owner_info.mother_title_bn) + '\n'
  } else {
    textContent2 += 'N/A' + '\n'
  }
  if (item.address) {
    textContent2 += AddressHelper.getCommonAddress(vm.getPresentAddressData(item.address)) + '\n'
  }
  const textContent3 = vm.$t('globalTrans.permanent_address') + ': '
  const textContent4 = item.address ? AddressHelper.getCommonAddress(vm.getPermanentAddressData(item.address)) : 'N/A'
  const customText = [
    { text: textContent, style: 'td', alignment: 'left', bold: true },
    { text: textContent2, style: 'td', alignment: 'left' },
    { text: textContent3, style: 'td', alignment: 'left', decoration: 'underline' },
    { text: textContent4, style: 'td', alignment: 'left' }
  ]
  return customText
}

const exportPdfDetails = async (baseUrl, uri, orgId, reportTitle, vm) => {
    try {
      Store.commit('mutateCommonProperties', {
        loading: true
      })
      if (i18n.locale === 'bn') {
        pdfMake.vfs = pdfFontsBn.pdfMake.vfs
      } else {
          pdfMake.vfs = pdfFontsEn.pdfMake.vfs
      }
      const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
      const pdfContent = [{
            columns: reportHeadData.reportHeadColumn
        },
        { text: reportHeadData.orgName, style: 'org', alignment: 'center' },
        { text: reportHeadData.projectName, style: 'address', alignment: 'center' },
        { text: reportHeadData.address, style: 'address', alignment: 'center' },
        { text: reportTitle, style: 'hh', alignment: 'center' }
      ]
      const currentLocale = vm.currentLocale
      // const fiscalYearId = vm.fiscalYearId
      // const item = vm.item
      // const itemDetails = vm.itemDetails
      pdfContent.push({ text: '(' + vm.$n(1) + ') ' + vm.$t('externalUser.export_product_sector') + ': ' + vm.productName(vm.listItem.product_service_sector_id), alignment: 'center', style: 'header2' })
      pdfContent.push({ text: '', style: 'fertilizer' })
      const leftTableBody = [
        [
          { text: '=>', style: 'th', alignment: 'center' },
          { text: vm.$t('exportTrophy.full_value'), style: 'td', alignment: 'center' },
          { text: vm.$n(100), style: 'td', alignment: 'center' }
        ],
        [
          { text: vm.$t('exportTrophy.serial_1'), style: 'td', alignment: 'center' },
          { text: vm.$t('exportTrophy.total_export_earning'), style: 'td', alignment: 'center' },
          { text: vm.$n(60), style: 'td', alignment: 'center' }
        ],
        [
          { text: vm.$t('exportTrophy.serial_2'), style: 'td', alignment: 'center' },
          { text: vm.$t('exportTrophy.total_increase_income'), style: 'td', alignment: 'center' },
          { text: vm.$n(10), style: 'td', alignment: 'center' }
        ],
        [
          { text: vm.$t('exportTrophy.serial_3'), style: 'td', alignment: 'center' },
          { text: vm.$t('exportTrophy.number_on_product_new_market'), style: 'td', alignment: 'center' },
          { text: vm.$n(10), style: 'td', alignment: 'center' }
        ],
        [
          { text: vm.$t('exportTrophy.serial_4'), style: 'td', alignment: 'center' },
          { text: vm.$t('exportTrophy.total_number_new_product_add'), style: 'td', alignment: 'center' },
          { text: vm.$n(10), style: 'td', alignment: 'center' }
        ],
        [
          { text: vm.$t('exportTrophy.serial_5'), style: 'td', alignment: 'center' },
          { text: vm.$t('exportTrophy.total_number_on_compliance'), style: 'td', alignment: 'center' },
          { text: vm.$n(10), style: 'td', alignment: 'center' }
        ]
      ]
      const rightTableBody = [
        [
          { text: '=>', style: 'th', alignment: 'center' },
          { text: vm.$t('exportTrophy.miminum_export_revenue'), style: 'td', alignment: 'center' },
          { text: vm.$n(70.00), style: 'td', alignment: 'center' }
        ],
        [
          { text: '=>', style: 'th', alignment: 'center' },
          { text: vm.$t('exportTrophy.total_application_number'), style: 'td', alignment: 'center' },
          { text: vm.$n(30), style: 'td', alignment: 'center' }
        ],
        [
          { text: '=>', style: 'th', alignment: 'center' },
          { text: vm.$t('exportTrophy.total_eligible_number'), style: 'td', alignment: 'center' },
          { text: vm.$n(27), style: 'td', alignment: 'center' }
        ],
        [
          { text: '=>', style: 'th', alignment: 'center' },
          { text: vm.$t('exportTrophy.incomplete_eligible'), style: 'td', alignment: 'center' },
          { text: vm.$n(0) + vm.$n(3), style: 'td', alignment: 'center' }
        ]
      ]
      pdfContent.push({
        columns: [
          {
            table: {
              headerRows: 1,
              widths: ['8%', '75%', '10%'],
              body: leftTableBody
            },
            style: {
              margin: [0, 0, 10, 0]
            }
          },
          {
            table: {
              headerRows: 1,
              widths: ['8%', '75%', '10%'],
              body: rightTableBody
            }
          }
        ]
      })
      pdfContent.push({ text: '', margin: [0, 0, 0, 20] })
      let customText
      if (currentLocale === 'en') {
        customText = 'Income growth in ' + vm.getFiscalYearName(vm.fiscalYearPrevTwoId) + ' fiscal year compared to' + vm.getFiscalYearName(vm.fiscalYearPrevId)
      } else {
        customText = vm.getFiscalYearName(vm.fiscalYearPrevTwoId) + ' এর তুলনায়' + vm.getFiscalYearName(vm.fiscalYearPrevId) + ' অর্থ বছরের রপ্তানি প্রবৃদ্ধি'
      }
      const allRows = [
        [
          { text: vm.$t('globalTrans.sl_no'), style: 'th', alignment: 'center', bold: true, rowSpan: 3 },
          { text: vm.$t('globalTrans.sl_no'), style: 'th', alignment: 'center', bold: true, rowSpan: 3 },
          { text: vm.$t('exportTrophy.org_name_address'), style: 'th', alignment: 'center', bold: true, rowSpan: 3 },
          { text: vm.$t('exportTrophy.organization_owner_info'), style: 'th', alignment: 'center', bold: true },
          { text: vm.$t('exportTrophyConfig.minimum_income_usd') + ' ' + vm.$t('exportTrophy.serial_1'), style: 'th', alignment: 'center', bold: true, colSpan: 3 },
          { },
          { },
          { text: customText + ' ' + vm.$t('exportTrophy.serial_1'), style: 'th', alignment: 'center', bold: true },
          // { text: vm.$t('exportTrophy.export_markets'), style: 'th', alignment: 'center', bold: true },
          { text: vm.$t('exportTrophy.entering_new_market') + ' ' + vm.$t('exportTrophy.serial_3'), style: 'th', alignment: 'center', bold: true },
          { text: vm.$t('exportTrophy.new_product_addition') + ' ' + vm.$t('exportTrophy.serial_4'), style: 'th', alignment: 'center', bold: true },
          { text: vm.$t('exportTrophy.compliance') + ' ' + vm.$t('exportTrophy.serial_5'), style: 'th', alignment: 'center', bold: true },
          { text: vm.$t('globalTrans.comments'), style: 'th', alignment: 'center', bold: true }
        ],
        [
          { },
          { },
          { },
          { text: '', style: 'th', alignment: 'center', bold: true },
          { text: vm.getFiscalYearName(vm.fiscalYearPrevTwoId), style: 'th', alignment: 'center', bold: true },
          { text: vm.getFiscalYearName(vm.fiscalYearPrevId), style: 'th', alignment: 'center', bold: true, colSpan: 2 },
          { },
          { },
          // { },
          { },
          { },
          { },
          { }
        ],
        [
          { text: vm.$n(1), style: 'th', alignment: 'center', bold: true },
          { text: vm.$n(2), style: 'th', alignment: 'center', bold: true },
          { text: vm.$n(3), style: 'th', alignment: 'center', bold: true },
          { text: vm.$n(4), style: 'th', alignment: 'center', bold: true },
          { text: vm.$n(5), style: 'th', alignment: 'center', bold: true },
          { text: vm.$t('exportTrophy.b_prc'), style: 'th', alignment: 'center', bold: true },
          { text: vm.$t('exportTrophy.bb_data'), style: 'th', alignment: 'center', bold: true },
          { text: vm.$n(6), style: 'th', alignment: 'center', bold: true },
          // { text: vm.$n(7), style: 'th', alignment: 'center', bold: true },
          { text: vm.$n(7), style: 'th', alignment: 'center', bold: true },
          { text: vm.$n(8), style: 'th', alignment: 'center', bold: true },
          { text: vm.$n(1), style: 'th', alignment: 'center', bold: true },
          { text: vm.$n(2), style: 'th', alignment: 'center', bold: true }
        ]
      ]
      if (vm.listData.length) {
        vm.listData.forEach((item, index) => {
          allRows.push(
            [
              { text: vm.$n(index + 1), style: 'td', alignment: 'center' },
              { text: (index < 9 ? vm.$n(0) : '') + vm.$n(index + 1), style: 'td', alignment: 'center' },
              {
                text: getOrganizationInfoText(vm, item),
                style: 'td',
                alignment: 'center'
              },
              {
                text: getOwnerInfoText(vm, item),
                style: 'td',
                alignment: 'center'
              },
              { text: vm.$n(item.evaluation.previous_export_income_usd, { useGrouping: false }), style: 'td', alignment: 'center' },
              { text: vm.$n(item.evaluation.current_export_income_usd, { useGrouping: false }), style: 'td', alignment: 'center' },
              { text: vm.$n(item.evaluation.bb_export_income_usd, { useGrouping: false }), style: 'td', alignment: 'center' },
              { text: vm.$n(item.evaluation.income_growth, { useGrouping: false }) + '%', style: 'td', alignment: 'center' },
              // { text: '', style: 'td', alignment: 'center' },
              { text: vm.getCountryNames(item.export_destination), style: 'td', alignment: 'center' },
              { text: vm.getProductNames(item.export_product), style: 'td', alignment: 'center' },
              { text: item.other_compliance && item.other_compliance.compliance_current_fiscal_year === 1 ? vm.$t('exportTrophy.compliance_info') : '-', style: 'td', alignment: 'center' },
              { text: item.evaluation.position > 0 ? (vm.$t('exportTrophy.position') + ': ' + vm.$n(item.evaluation.position, { useGrouping: false })) : '-', style: 'td', alignment: 'center' }
            ],
            [
              { text: '', style: 'td', alignment: 'center' },
              { text: '', style: 'td', alignment: 'center' },
              { text: '', style: 'td', alignment: 'center' },
              { text: '', style: 'td', alignment: 'center' },
              { text: '', style: 'td', alignment: 'center' },
              { text: '', style: 'td', alignment: 'center' },
              { text: vm.$n(item.evaluation.bb_export_income_number), style: 'td', alignment: 'center' },
              { text: vm.$n(item.evaluation.income_growth_number), style: 'td', alignment: 'center' },
              // { text: '', style: 'td', alignment: 'center' },
              { text: vm.$n(item.evaluation.new_destination_number), style: 'td', alignment: 'center' },
              { text: vm.$n(item.evaluation.new_product_number), style: 'td', alignment: 'center' },
              { text: vm.$n(item.evaluation.compliance_number), style: 'td', alignment: 'center' },
              { text: vm.$n(item.evaluation.total_number), style: 'td', alignment: 'center' }
            ]
          )
        })
      }
      pdfContent.push({
        table: {
          headerRows: 3,
          widths: ['5%', '5%', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
          body: allRows
        }
      })
      var docDefinition = {
      content: pdfContent,
      pageSize: 'A3',
      pageOrientation: 'landscape',
      styles: {
        th: {
          fontSize: (i18n.locale === 'bn') ? 8 : 7,
          margin: [1, 1, 1, 1]
        },
        td: {
          fontSize: (i18n.locale === 'bn') ? 8 : 7,
          margin: [1, 1, 1, 1]
        },
        search: {
          fontSize: (i18n.locale === 'bn') ? 10 : 11,
          margin: [3, 3, 3, 3]
        },
        fertilizer: {
          margin: [5, 0, 0, 5]
        },
        header: {
            fontSize: 16,
            bold: true,
            margin: [0, 0, 0, 0]
        },
        org: {
            fontSize: 13,
            bold: true,
            margin: [0, -25, 10, 5]
        },
        address: {
            fontSize: 11,
            margin: [0, 0, 0, 5]
        },
        hh: {
            fontSize: 13,
            bold: true,
            margin: [10, 10, 25, 20]
        },
        header2: {
          fontSize: 10,
          margin: [0, 10, 0, 20],
          bold: true,
          decoration: 'underline'
        },
        header3: {
          fontSize: 10,
          margin: [0, 6, 0, 0]
        },
        header4: {
          fontSize: 9,
          margin: [0, 6, 0, 9]
        },
        headerPort1: {
          fontSize: 10,
          margin: [0, 20, 0, 5]
        },
        headerPort: {
          fontSize: 10,
          margin: [0, 4, 0, 11]
        },
        krishi: {
          margin: [0, -5, 0, 11],
          alignment: 'center'
        },
        tableSubHead: {
          margin: [0, 5, 0, 11]
        }
      }
    }
    pdfMake.createPdf(docDefinition, null, null, null).print()
  } catch (error) {
    if (error) {}
  }
  Store.commit('mutateCommonProperties', {
    loading: false
  })
}
export default {
  exportPdfDetails
}
