import i18n from '@/i18n'
import Store from '@/store'
import ReportHeading from '@/utils/report-head'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_pdf_kal_fonts_bn.js'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'
import AddressHelper from '@/utils/area-type-address'

function getOrgAddress (item) {
  let orgAddress = ''
  orgAddress += item.org_info ? ((i18n.locale === 'bn' ? item.org_info.org_name_bn : item.org_info.org_name_en) + '\n') : ''
  orgAddress += item.org_address ? AddressHelper.getCommonAddress(item.org_address) : ''
  return orgAddress
}

const exportPdfDetails = async (baseUrl, uri, orgId, reportTitle, vm) => {
    try {
      Store.commit('mutateCommonProperties', {
        loading: true
      })
      if (i18n.locale === 'bn') {
        pdfMake.vfs = pdfFontsBn.pdfMake.vfs
        pdfMake.fonts = {
          Kalpurush: {
            normal: 'Kalpurush.ttf',
            bold: 'Kalpurush.ttf',
            italics: 'Kalpurush.ttf',
            bolditalics: 'Kalpurush.ttf'
          }
        }
      } else {
          pdfMake.vfs = pdfFontsEn.pdfMake.vfs
          pdfMake.fonts = {
            Roboto: {
              normal: 'Roboto-Regular.ttf',
              bold: 'Roboto-Medium.ttf',
              italics: 'Roboto-Italic.ttf',
              bolditalics: 'Roboto-MediumItalic.ttf'
            }
          }
      }
      const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
      const pdfContent = [{
            columns: reportHeadData.reportHeadColumn
        },
        { text: reportHeadData.orgName, style: 'org', alignment: 'center' },
        { text: reportHeadData.projectName, style: 'address', alignment: 'center' },
        { text: reportHeadData.address, style: 'address', alignment: 'center' },
        { text: reportTitle, style: 'hh', alignment: 'center' }
      ]
      const allRows = [
        [
          { text: vm.$t('globalTrans.sl_no'), style: 'th', alignment: 'center', bold: true },
          { text: vm.$t('globalTrans.application_id'), style: 'th', alignment: 'center', bold: true },
          { text: vm.$t('exportTrophy.org_name_address'), style: 'th', alignment: 'center', bold: true },
          { text: vm.$t('exportTrophy.owner_or_md_name'), style: 'th', alignment: 'center', bold: true },
          { text: vm.$t('externalUser.tin_no'), style: 'th', alignment: 'center', bold: true },
          { text: vm.$t('globalTrans.vat') + '/ ' + vm.$t('externalUser.bin_no'), style: 'th', alignment: 'center', bold: true },
          { text: vm.$t('exportTrophy.number_obtained'), style: 'th', alignment: 'center', bold: true },
          { text: vm.$t('exportTrophy.position'), style: 'th', alignment: 'center', bold: true }
        ]
      ]
      if (vm.listData.length) {
        vm.listData.forEach((item, index) => {
          allRows.push([
            { text: vm.$n(index + 1), alignment: 'center', style: 'td' },
            { text: vm.$n(item.application_id, { useGrouping: false }), alignment: 'center', style: 'td' },
            { text: getOrgAddress(item), alignment: 'center', style: 'td' },
            { text: (i18n.locale === 'bn') ? item.owner_info.owner_name_bn : item.owner_info.owner_name_en, alignment: 'center', style: 'td' },
            { text: item.org_tax_info ? vm.$n(item.org_tax_info.tin_no, { useGrouping: false }) : ' ', alignment: 'center', style: 'td' },
            { text: item.org_info ? vm.$n(item.org_info.bin_no, { useGrouping: false }) : ' ', alignment: 'center', style: 'td' },
            { text: item.evaluation ? vm.$n(item.evaluation.total_number, { useGrouping: false }) : ' ', alignment: 'center', style: 'td' },
            { text: item.evaluation ? vm.$n(item.evaluation.position, { useGrouping: false }) : ' ', alignment: 'center', style: 'td' }
          ])
        })
      } else {
        allRows.push([
          { text: vm.$t('globalTrans.noDataFound'), alignment: 'center', style: 'td', colSpan: 8 },
          { },
          { },
          { },
          { },
          { },
          { },
          { }
        ])
      }
      pdfContent.push({
        table: {
          headerRows: 1,
          widths: ['10%', '10%', '25%', '15%', '10%', '10%', '10%', '10%'],
          body: allRows
        }
      })
        // const waterMarkText = i18n.locale === 'bn' ? 'বাণিজ্য মন্ত্রণালয়' : 'Ministry of Commerce'
        var docDefinition = {
        content: pdfContent,
        pageSize: 'A4',
        pageOrientation: 'portrait',
        // watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
        defaultStyle: {
          font: (i18n.locale === 'bn') ? 'Kalpurush' : 'Roboto'
        },
        styles: {
          th: {
            fontSize: (i18n.locale === 'bn') ? 9 : 8,
            margin: [3, 3, 3, 3]
          },
          td: {
            fontSize: (i18n.locale === 'bn') ? 9 : 8,
            margin: [3, 3, 3, 3]
          },
          search: {
            fontSize: (i18n.locale === 'bn') ? 10 : 11,
            margin: [3, 3, 3, 3]
          },
          fertilizer: {
            margin: [5, 0, 0, 5]
          },
          header: {
              fontSize: 16,
              bold: true,
              margin: [0, 0, 0, 0]
          },
          org: {
              fontSize: 13,
              bold: true,
              margin: [0, -25, 10, 5]
          },
          address: {
              fontSize: 11,
              margin: [0, 0, 0, 5]
          },
          hh: {
              fontSize: 13,
              bold: true,
              margin: [10, 10, 25, 20]
          },
          header2: {
            fontSize: 11,
            margin: [0, 10, 0, 0]
          },
          header3: {
            fontSize: 10,
            margin: [0, 6, 0, 0]
          },
          header4: {
            fontSize: 9,
            margin: [0, 6, 0, 9]
          },
          headerPort1: {
            fontSize: 10,
            margin: [0, 20, 0, 5]
          },
          headerPort: {
            fontSize: 10,
            margin: [0, 4, 0, 11]
          },
          krishi: {
            margin: [0, -5, 0, 11],
            alignment: 'center'
          },
          tableSubHead: {
            margin: [0, 5, 0, 11]
          }
        }
      }
      pdfMake.createPdf(docDefinition, null, null, null).print()
    } catch (error) {
      if (error) {}
    }
    Store.commit('mutateCommonProperties', {
      loading: false
    })
}
export default {
  exportPdfDetails
}
